<template>
  <TTView>
    <VRow>
      <VCol>
        <LevelForm
          :entity="level"
          :tests="tests"
          :loading="loading"
          @update:name="level.name = $event"
          @update:threshold="level.threshold = $event"
          @update:value="level.value = $event"
          @update:testId="level.testId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import LevelForm from '../../../components/multi-level-test/levels/LevelForm.vue';

export default {
  name: 'TestsLevelsEdit',

  components: {
    LevelForm,
  },

  data() {
    return {
      loading: false,
      tests: [],
      level: {
        name: null,
        threshold: 0,
        value: 0,
        testId: null,
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },
  },

  async created() {
    await this.fetch();

    this.level.testId = this.testId;
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { levelId } = this;
        const data = { id: levelId };

        const { tests } = await this.$di.api.MultiLevelTest.TestsIndex();
        const { level } = await this.$di.api.MultiLevelTest.LevelsGet({ data });

        this.tests = tests;
        this.level = level;
      } catch (err) {
        // no-error
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { levelId, level } = this;
        const data = { id: levelId, level };
        delete level.id;

        await this.$di.api.MultiLevelTest.LevelsUpdate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
